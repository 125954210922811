export const environment = {
    production: false,
    // apiUrl: 'http://127.0.0.1:7011/api',
    // socketUrl: 'http://127.0.0.1:7011',
    apiUrl: 'https://tejilo.com/cd',
    socketUrl: 'https://tejilo.com',
    // apiUrl:'https://saraldiscount.ktm.yetiappcloud.com/api',
    // socketUrl: 'https://saraldiscount.ktm.yetiappcloud.com',


    adminPath: "/",
    localSTORAGE: {
        token: "CDTOKEN",
        profile: "CDPROFILE",
    },
    maxFileSize: 5 * 1024 * 1024, // 5MB
    imgUrl: 'https://tejilo.com/cd/resourcesUrl/',
    // imgUrl: 'https://saraldiscount.ktm.yetiappcloud.com/api/resourcesUrl/',
    // imgUrl: 'http://localhost:7011/api/resourcesUrl/',
    plan: {
        basic: {
            id: 1,
            name: 'basic',
            amount: 0,
            description: 'Basic plan',
            collabLimit: 5,
            employeeLimit: 10,
            duration: 'lifeTime'

        },
        standard: {
            id: 1,
            name: 'standard',
            amount: 10000,
            description: 'Standard plan',
            collabLimit: 20,
            employeeLimit: 50,
            duration: '1 Year'

        },
        premium:
        {
            id: 2,
            name: 'premium',
            amount: 30000,
            description: 'Premium plan',
            collabLimit: 50,
            employeeLimit: 100,
            duration: '1 Year'

        },

    },
    planList: [
        {
            name: 'Basic Plan',
            value: 'basic'
        },
        {
            name: 'Standard Plan',
            value: 'standard'
        },
        {
            name: 'Premium Plan',
            value: 'premium'
        },
        {
            name: 'Enterprise Plan',
            value: 'enterprise'
        }
    ],
    createTransactionPlanList: [

        {
            name: 'Standard Plan',
            value: 'standard'
        },
        {
            name: 'Premium Plan',
            value: 'premium'
        },
        {
            name: 'Enterprise Plan',
            value: 'enterprise'
        },

    ],
    salesQueryPlanList: [

        {
            name: 'Standard Plan',
            value: 'standard'
        },
        {
            name: 'Premium Plan',
            value: 'premium'
        },
        {
            name: 'Enterprise Plan',
            value: 'enterprise'
        },

    ],
    paymentModeList: [
        {
            name: 'Bank Transfer',
            value: 'bankTransfer'
        },
        {
            name: 'Cash',
            value: 'cash'
        },
        {
            name: 'cheque',
            value: 'cheque'
        },
        {
            name: 'Online Payment',
            value: 'onlinePayment'
        },
    ],
    bankTypeList: [
        {
            name: 'SBI',
            value: 'sbi'
        },
        {
            name: 'Everest Bank',
            value: 'everestBank'
        },
        {
            name: 'NIC Asia Bank',
            value: 'nicAsiaBank'
        }
    ],
    upgradePlanList: [

        {
            name: 'Standard Plan',
            value: 'standard'
        },
        {
            name: 'Premium Plan',
            value: 'premium'
        },
        {
            name: 'Enterprise Plan',
            value: 'enterprise'
        },

    ],

};

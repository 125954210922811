import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import { AuthService } from '../services/auth.service';
import { AppStorageService } from '../services/app-storage.service';


@Injectable()
export class HaAuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private appstorageservice: AppStorageService,
    private router: Router
  ) {
  }
  canActivate() {
    if (this.authService.isLoggedIn()) {
      let currentUser: any = (this.appstorageservice.getdata(environment.localSTORAGE.profile));
      currentUser = currentUser ? JSON.parse(this.appstorageservice.getdata(environment.localSTORAGE.profile)) : '';

      if (currentUser && (currentUser.type !== '1') && (currentUser && currentUser.type !== '2')) { // Have to handle carefully later
        return true;
      } else {
        return false;
      }
    } else {
      return this.router.navigate(['/auth/login']);
    }
  }
}

import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ConfirmationService, Confirmation } from 'primeng/api';
@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private confirmations: { [key: string]: Confirmation } = {};

  constructor(private messageService: MessageService, private confirmationService: ConfirmationService) { }
  title:any = 'Discount-Project'

  show(message: string, status: boolean = false) {
    if (status) {
      this.messageService.add({ severity: 'success', detail: `Success: ${message}` });
    } else {
      this.messageService.add({ severity: 'error', detail: `Error: ${message}` });
    }
  }

  confirm(message: string, key: string = 'confirm') { // confirm is the key for trigger the toast
    return new Promise((resolve, reject) => {
      this.confirmationService.confirm({
        key: key,
        message: message,
        accept: () => resolve(true),
        reject: () => resolve(false),
      })

    })

  }
  proceed(event: Event): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'Are you sure you want to proceed?',
        icon: 'pi pi-exclamation-triangle',
        // acceptButtonStyleClass: 'p-button-danger p-button-sm',
        accept: () => {
          this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Record deleted', life: 3000 });
          resolve(true);
        },
        reject: () => {
          this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
          resolve(false);
        }
      });
    });
  }
  
  delete(event: Event): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'Do you want to delete this record?',
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass: 'p-button-danger p-button-sm',
        accept: () => {
          this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Record deleted', life: 3000 });
          resolve(true);
        },
        reject: () => {
          this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
          resolve(false);
        }
      });
    });
  }

  public showTitle(titleName) {
    titleName = 'Discount Project | ' + titleName;
    this.title.setTitle(titleName);
  }
  


}

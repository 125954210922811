import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  constructor(private socket: Socket) { }

  inactive() {
    return this.socket.fromEvent('inactive');
  }
  delete() {
    return this.socket.fromEvent('delete');
  }

  emitInactiveCompany(company: any) {
    this.socket.emit('inactive-company', company);
  }


  emitInactiveUser(user: any) {
    this.socket.emit('inactive-user', user);
  }
  emitDeleteUser(user: any) {
    this.socket.emit('delete-user', user);
  }
  message() {
    return this.socket.fromEvent('message');
  }
  emitMessage(data: any) {
    this.socket.emit('send-message', data)
  }

  emitReloadRequestListForReceiver() {
    this.socket.emit('reloadRequestListForReceiver');

  }
  listnerReload() {
    return this.socket.fromEvent('reload-dr-request');
  }
  emitReloadRequestListForProvider() {
    this.socket.emit('reloadRequestListForProvider');

  }
  listnerReloadDp() {
    return this.socket.fromEvent('reload-dp-request');
  }

  // Socket Service v2

  sendRequest(event: string, id: any) { // Handle both provider request and receiver request
    return this.socket.emit(event, id)

  }
  cancelRequest(event: string, id: any) {
    return this.socket.emit(event, id)
  }
  endTieUp(event: string, id: any) {
    return this.socket.emit(event, id)

  }

  updateOfferDiscount(event: string, id: any) {
    return this.socket.emit(event, id)
  }

  actionRequest(event: string, id: any) {
    return this.socket.emit(event, id)
  }



  // onReloadReceiverList() {
  //   return this.socket.fromEvent('receiver:reloadList');

  // }
  onReloadReceiverList(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('receiver:reloadList', (id: any) => {
        observer.next(id);
      });
    });
  }
  onReloadReceiverListOurCollab(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('receiver:reloadListOurCollab', (id: any) => {
        observer.next(id);
      });
    });
  }

  onReloadProviderList(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('provider:reloadList', (id: any) => {
        observer.next(id);
      });
    });
  }
  onEndTieUpProviderList(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('provider:reloadListOurCollab', (id: any) => {
        observer.next(id);
      });
    });
  }

  onPendingRequestAction(event: any, id: any) {
    return this.socket.emit(event, id)
  }


  afterPendingRequestAccept(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('admin:handleVerificationRequest', (id: any) => {
        observer.next(id);
      });
    });

  }

  sendOtp(event: string, id: any) {
    return this.socket.emit(event, id)

  }
  verifyOtp(event: string, id: any) {
    return this.socket.emit(event, id)

  }
  linkUser(event: string, id: any) {
    return this.socket.emit(event, id)

  }
  recieveOtp(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('66cdaa9882850a2c7be9b226:fetchOtpData', (id: any) => {
        observer.next(id);
      });
    });
  }
  linkedAccount(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('66cdaa9882850a2c7be9b226:fetchCompanyData', (id: any) => {
        observer.next(id);
      });
    });
  }


}



import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppStorageService } from '../services/app-storage.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class CanAccessGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private appStorageService: AppStorageService,
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['/auth/login']); // Redirect to the login page
      return false;
    }

    const currentUser = JSON.parse(this.appStorageService.getdata(environment.localSTORAGE.profile));
    if (!currentUser || !currentUser.adminRole || !currentUser.adminRole.moduleId) {
      return false;
    }

    const moduleId = next.params['moduleId'];
    const matchedObject = currentUser.adminRole.moduleId.find(obj => obj._id === moduleId);
    if (matchedObject) {
      return true; // User has access to the module
    } else {
      return false;
    }
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  public isLoggedIn() {
    return localStorage.getItem(environment.localSTORAGE.token) != null;
  }
}

import { Component } from '@angular/core';
import { AppStorageService } from 'src/app/services/app-storage.service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';
import { ToastService } from 'src/app/services/toast.service';
import { SocketService } from 'src/app/services/socket.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  loginUser: any = ''
  notificationList: any = []
  linkRequestList: any = []
  isLoading:boolean = false;
  
  selectedEmployee: any = []
  unAssignedUserList: any = []

  notificationCount: any = 0
  notificationSidebar: boolean = false;
  linkRequestSidebar: boolean = false
  constructor(private appstorageService: AppStorageService, private userService: UserService, private toastService: ToastService, private socketService: SocketService, private router: Router) {

  }
  ngOnInit(): void {
    let currentUser: any = (this.appstorageService.getdata(environment.localSTORAGE.profile));
    currentUser = currentUser ? JSON.parse(this.appstorageService.getdata(environment.localSTORAGE.profile)) : '';
    this.loginUser = currentUser;
    if (this.loginUser.type === '1') {
      this.dpNotificationList()


    } else if (this.loginUser.type === '2') {
      this.drNotificationList()
      this.getUnassignedUserList()
      this.getLinkRequestList()
      


    }

    this.setupSocketListeners()

  }

  getUnassignedUserList() {
    this.isLoading = true;
    this.userService.getDrUnassigendList().subscribe({
        next: (res) => {
          console.log(res);
          this.isLoading = false;
          if(res && res.statusCode === 200){
            this.unAssignedUserList = res?.data?.empList;
            this.getLinkRequestList()
          // this.toastService.show(res.message, true);
          } else {
          this.toastService.show(res.message);
          }
        },
        error: (err) => {
          this.toastService.show(err.message);
          console.error(err);
        }
    });
    

  }

  getLinkRequestList(){
    this.isLoading = true;
    this.userService.getDrLinkRequestList().subscribe({
      next: (res) => {
    this.isLoading = false;

        if (res && res.statusCode === 200) {
          console.log(res)
          // this.toastService.show(res.message, true);
          this.linkRequestList = res.data?.linkRequestList;
        } else {
          this.toastService.show(res.message);
        }
      },
      error: (err) => {
    this.isLoading = false;
        this.toastService.show(err.message);
        console.error(err);
      }
    });
    
  }

  dpNotificationList() {
    this.userService.getDpNotificationList().subscribe({
      next: (res) => {
        if (res && res.statusCode === 200) {
          // this.toastService.show(res.message, true);
          this.notificationList = res.data?.notificationList;
          this.notificationCount = res.data?.unreadNotifications;
        } else {
          this.toastService.show(res.message);
        }
      },
      error: (err) => {
        this.toastService.show(err.message);
        console.error(err);
      }
    });
  }
  drNotificationList() {
    this.userService.getDrNotificationList().subscribe({
      next: (res) => {
        if (res && res.statusCode === 200) {
          // this.toastService.show(res.message, true);
          this.notificationList = res.data?.notificationList;
          this.notificationCount = res.data?.unreadNotifications;

        } else {
          this.toastService.show(res.message);
        }
      },
      error: (err) => {
        this.toastService.show(err.message);
        console.error(err);
      }
    });
  }

  setupSocketListeners() {
    this.socketService.onReloadReceiverList().subscribe((id: any) => {
      if (this.loginUser) {
        if (this.loginUser._id === id) {
          if (this.loginUser.type === '1') {
            this.dpNotificationList()
          } else if (this.loginUser.type === '2') {
            this.drNotificationList()
          }
        }
      }

    })
    this.socketService.onReloadProviderList().subscribe((id) => {
      if (this.loginUser) {
        if (this.loginUser._id === id) {
          if (this.loginUser.type === '1') {
            this.dpNotificationList()
          } else if (this.loginUser.type === '2') {
            this.drNotificationList()
          }
        }
      }

    })
  }


  viewNotification() {
    this.notificationSidebar = true;
    this.notificationCount = 0;
    const payload = {
      isRead: true
    }

    if (this.loginUser.type === '1') {
      this.userService.DpReadNotification(payload).subscribe({
        next: (res) => {
          if (res && res.statusCode === 200) {
            // this.toastService.show(res.message, true);
            this.dpNotificationList()


          } else {
            this.toastService.show(res.message);
          }
        },
        error: (err) => {
          this.toastService.show(err.message);
          console.error(err);
        }
      });

    } else if (this.loginUser.type === '2') {
      this.userService.DrReadNotification(payload).subscribe({
        next: (res) => {
          if (res && res.statusCode === 200) {
            // this.toastService.show(res.message, true);
            this.drNotificationList()


          } else {
            this.toastService.show(res.message);
          }
        },
        error: (err) => {
          this.toastService.show(err.message);
          console.error(err);
        }
      });

    }


  }
  viewLinkRequest() {
    this.linkRequestSidebar = true;


  }

  async handleLogout() {
    const confirm = await this.toastService.confirm(`Are you sure you want to Sign Out?`)
    if (confirm) {
      localStorage.removeItem(environment.localSTORAGE.profile)
      localStorage.removeItem(environment.localSTORAGE.token)
      this.router.navigate(['/auth/login'])
    }
  }

  assign(item: any, selectedEmployee: any) {
    if(!selectedEmployee){
      return this.toastService.show(`You must select an employee before accept`)
    }
    const payload = {
      reqId:item._id,
      empId: selectedEmployee._id,
      userId: item.userId
    }
    this.isLoading = true;
    this.userService.assignUserToEmployee(payload).subscribe({
        next: (res) => {
          this.isLoading = false;
          console.log(res);
          if(res && res.statusCode === 200){
          this.toastService.show(res.message, true);
          this.getLinkRequestList()
          } else {
          this.toastService.show(res.message);
          }
        },
        error: (err) => {
          this.isLoading = false;
          this.toastService.show(err.message);
          console.error(err);
        }
    });



  }

  async rejectRequest(item:any){
    const confirm = await this.toastService.confirm('Are you sure you want to reject');
    if(confirm){
      const payload = {
        reqId:item._id,
      }
      this.isLoading = true;
      this.userService.rejectLinkRequest(payload).subscribe({
        next: (res) => {
          this.isLoading = false;
          console.log(res);
          if(res && res.statusCode === 200){
          this.toastService.show(res.message, true);
          this.getLinkRequestList()
          } else {
          this.toastService.show(res.message);
          }
        },
        error: (err) => {
          this.isLoading = false;
          this.toastService.show(err.message);
          console.error(err);
        }
      });

    }
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareRoutingModule } from './share-routing.module';

import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PasswordModule } from 'primeng/password';
import { DividerModule } from 'primeng/divider';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';
import { CardModule } from 'primeng/card';
import { ToolbarModule } from 'primeng/toolbar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { StepperModule } from 'primeng/stepper';
import { MultiSelectModule } from 'primeng/multiselect';
import { RatingModule } from 'primeng/rating';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TreeModule } from 'primeng/tree';
import { PaginatorModule } from 'primeng/paginator';
import { CalendarModule } from 'primeng/calendar';

import { AccordionModule } from 'primeng/accordion';
// import { MeterGroupModule } from 'primeng/metergroup';
import { FileUploadModule } from 'primeng/fileupload';
import { MenuModule } from 'primeng/menu';
import { EditorModule } from 'primeng/editor';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputOtpModule } from 'primeng/inputotp';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ChartModule } from 'primeng/chart'
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { SkeletonModule } from 'primeng/skeleton';
import { InputGroupModule } from 'primeng/inputgroup';
import { LeftSideBarComponent } from './left-side-bar/left-side-bar.component';
import { HeaderComponent } from './header/header.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { CarouselModule } from 'primeng/carousel';
// import { ToggleButtonModule } from 'primeng/togglebutton';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { SidebarModule } from 'primeng/sidebar';




@NgModule({
  declarations: [

    LoaderComponent,
    LeftSideBarComponent,
    HeaderComponent,
    NotFoundComponent
  ],
  imports: [
    CommonModule,
    ShareRoutingModule,
    ToastModule,
    ButtonModule,
    InputTextModule,
    CheckboxModule,
    TooltipModule,
    OverlayPanelModule,
    ConfirmDialogModule,
    PasswordModule,
    DividerModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    ToolbarModule,
    DialogModule,
    DropdownModule,
    TableModule,
    BreadcrumbModule,
    MultiSelectModule,
    RatingModule,
    InputSwitchModule,
    TabMenuModule,
    TabViewModule,
    TreeModule,
    AccordionModule,
    FileUploadModule,
    MenuModule,
    ProgressBarModule,
    InputOtpModule,
    ConfirmPopupModule,
    MessageModule,
    MessagesModule,
    ChartModule,
    AvatarGroupModule,
    AvatarModule,
    SkeletonModule,
    PaginatorModule,
    CalendarModule,
    InputGroupModule,
    CarouselModule,
    StepperModule,
    // ToggleButtonModule,
    IconFieldModule,
    InputIconModule,
    SidebarModule
    // MeterGroupModule,





  ],
  exports: [

    ToastModule,
    ButtonModule,
    InputTextModule,
    CheckboxModule,
    TooltipModule,
    OverlayPanelModule,
    ConfirmDialogModule,
    PasswordModule,
    DividerModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderComponent,
    CardModule,
    ToolbarModule,
    DialogModule,
    DropdownModule,
    TableModule,
    BreadcrumbModule,
    MultiSelectModule,
    RatingModule,
    InputSwitchModule,
    TabMenuModule,
    TabViewModule,
    TreeModule,
    AccordionModule,
    FileUploadModule,
    MenuModule,
    ProgressBarModule,
    InputOtpModule,
    ConfirmPopupModule,
    MessageModule,
    MessagesModule,
    ChartModule,
    AvatarGroupModule,
    AvatarModule,
    SkeletonModule,
    PaginatorModule,
    CalendarModule,
    InputGroupModule,
    LeftSideBarComponent,
    HeaderComponent,
    NotFoundComponent,
    CarouselModule,
    StepperModule,
    // ToggleButtonModule,
    IconFieldModule,
    InputIconModule,
    SidebarModule
    // MeterGroupModule



  ]
})
export class ShareModule { }

import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Router } from '@angular/router';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';

import { UserService } from './user.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {
  public API_ENDPOINT = environment.apiUrl + 'api/';

  constructor(
    private router: Router,
    private userService: UserService,

  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let authReq: HttpRequest<any>;

    if (req.body instanceof FormData) {
      authReq = req.clone({
        headers: req.headers
          .set('Authorization', 'Bearer ' + this.getToken())
      });

    } else {
      authReq = req.clone({
        headers: req.headers
          .set('Content-Type', 'application/json')
          .set('Authorization', 'Bearer ' + this.getToken())
      });
    }

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          localStorage.removeItem(environment.localSTORAGE.token)
          localStorage.removeItem(environment.localSTORAGE.profile)
          this.userService.handle401Error(true);
          this.router.navigate(['/auth/login']);
        }
        return throwError(() => error);
      })
    );
  }

  protected getToken() {

    return localStorage.getItem(environment.localSTORAGE.token);
  }
}

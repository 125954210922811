<aside class="main-sidebar sidebar-light-gray elevation-6" style="background-color: var(--background-color);">
    <!-- Brand Logo -->
    <!-- <a class="brand-link navbar-gray" style="background-color: var(--background-color);">

        <img *ngIf="loginUser.logo" [src]="imgUrl + loginUser.logo" class="brand-image"
            style="opacity: .8; height: 28px; width: 28px; border-radius: 50%;" />
        <img *ngIf="!loginUser.logo" src="assets/user-icon.png" class="brand-image"
            style="opacity: .8; height: 28px; width: 28px; border-radius: 50%;" />
        <span class="brand-text font-weight-light"
            style="word-wrap: break-word; white-space: normal; max-width: 200px; display: inline-block;">
            {{loginUser.companyBusinessName ? loginUser.companyBusinessName : loginUser.fullName }}
        </span>


    </a> -->

    <!-- Sidebar -->
    <div class="sidebar">
        <div class="img-section flex justify-content-center mb-3">
            <img *ngIf="loginUser?.logo" [src]="imgUrl + loginUser?.logo" class="brand-image"
                style=" height: 40px; width: 40px; border-radius: 50%; object-fit: cover;" />
            <img *ngIf="!loginUser?.logo" src="assets/user-icon.png" class="brand-image"
                style=" height: 40px; width: 40px; border-radius: 50%;" />
        </div>


        <div class="brand-name-section">

            <h5 class="brand-text font-weight-light"
                style="word-wrap: break-word; white-space: normal; max-width: 200px; display: inline-block;">
                {{loginUser?.companyBusinessName ? loginUser?.companyBusinessName : loginUser?.fullName }}
            </h5>
        </div>


        <nav class="mt-5">
            <ul *ngIf="loginUser?.type === '1' && loginUser?.accountStatus !== 'applied'"
                class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li class="nav-item">
                    <a [routerLink]="['/home/discount-provider/dashboard']" routerLinkActive="active"
                        class="nav-link  cursor-pointer">
                        <i class="pi pi-fw pi-home"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <p>
                            Dashboard
                        </p>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/home/discount-provider/mydiscounts']" routerLinkActive="active"
                        class="nav-link  cursor-pointer">
                        <i class="pi pi-fw pi-indian-rupee"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <p>
                            My Discount
                        </p>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/home/discount-provider/in-pending-request']" routerLinkActive="active"
                        class="nav-link  cursor-pointer">
                        <i class="pi pi-fw pi-arrow-right-arrow-left"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <p>
                            In Pending Requests
                        </p>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/home/discount-provider/out-pending-request']" routerLinkActive="active"
                        class="nav-link  cursor-pointer">
                        <i class="pi pi-fw pi-arrow-right-arrow-left"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <p>
                            Out Pending Requests
                        </p>
                    </a>
                </li>
             
                <li class="nav-item">
                    <a [routerLink]="['/home/discount-provider/our-collaborators']" routerLinkActive="active"
                        class="nav-link  cursor-pointer">
                        <i class="pi pi-fw pi-users"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <p>
                            Our Collaborators
                        </p>
                        <!-- <span class="badge badge-danger">5</span> -->
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/home/discount-provider/request-for-collab']" routerLinkActive="active"
                        class="nav-link  cursor-pointer">
                        <i class="pi pi-fw pi-list"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <p>
                            Request For Collab
                        </p>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/home/discount-provider/staff-management']" routerLinkActive="active"
                        class="nav-link  cursor-pointer">
                        <i class="pi pi-fw pi-users"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <p>
                            Staff Management
                        </p>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/home/discount-provider/profile']" routerLinkActive="active"
                        class="nav-link  cursor-pointer">
                        <i class="pi pi-fw pi-user"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <p>
                            Profile
                        </p>
                    </a>
                </li>

            </ul>
            <!-- For the staff -->
            <ul *ngIf="loginUser?.type === '3' && loginUser?.accountStatus !== 'applied'"
                class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li class="nav-item">
                    <a [routerLink]="['/home/staff/dashboard']" routerLinkActive="active"
                        class="nav-link  cursor-pointer">
                        <i class="pi pi-fw pi-home"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <p>
                            Dashboard
                        </p>
                    </a>
                </li>

                <li class="nav-item">
                    <a [routerLink]="['/home/staff/profile']" routerLinkActive="active"
                        class="nav-link  cursor-pointer">
                        <i class="pi pi-fw pi-user"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <p>
                            Profile
                        </p>
                    </a>
                </li>

            </ul>
            <!-- For The Verification -->
            <ul *ngIf="loginUser?.accountStatus === 'applied'" class="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview" role="menu" data-accordion="false">

                <li class="nav-item">
                    <a [routerLink]="['/home/verification-process/welcome']" routerLinkActive="active"
                        class="nav-link  cursor-pointer">
                        <i class="pi pi-fw pi-user"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <p>
                            Profile
                        </p>
                    </a>
                </li>

            </ul>

            <ul *ngIf="loginUser?.type === '2' && loginUser?.accountStatus !== 'applied'"
                class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li class="nav-item">
                    <a [routerLink]="['/home/discount-receiver/dashboard']" routerLinkActive="active"
                        class="nav-link  cursor-pointer">
                        <i class="pi pi-fw pi-home"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <p>
                            Dashboard
                        </p>
                    </a>
                </li>
            
                <li class="nav-item">
                    <a [routerLink]="['/home/discount-receiver/in-pending-request']" routerLinkActive="active"
                        class="nav-link  cursor-pointer">
                        <i class="pi pi-fw pi-arrow-right-arrow-left"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <p>
                            In Pending Request
                        </p>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/home/discount-receiver/out-pending-request']" routerLinkActive="active"
                        class="nav-link  cursor-pointer">
                        <i class="pi pi-fw pi-arrow-right-arrow-left"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <p>
                            Out Pending Request
                        </p>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/home/discount-receiver/our-collaborators']" routerLinkActive="active"
                        class="nav-link  cursor-pointer">
                        <i class="pi pi-fw pi-users"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <p>
                            Our Collaborators
                        </p>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/home/discount-receiver/request-for-collab']" routerLinkActive="active"
                        class="nav-link  cursor-pointer">
                        <i class="pi pi-fw pi-list"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <p>
                            Request For Collab
                        </p>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/home/discount-receiver/profile']" routerLinkActive="active"
                        class="nav-link  cursor-pointer">
                        <i class="pi pi-fw pi-user"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <p>
                            Profile
                        </p>
                    </a>
                </li>
            </ul>

            <ul *ngIf="!(loginUser?.type === '1') && !(loginUser?.type==='2') && loginUser?.accountStatus !== 'applied'"
                class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li class="nav-item" *ngFor="let module of loginUser?.adminRole?.moduleId">


                    <a [routerLink]="['/home/ha-admin/' + formatModuleName(module?.name) + '/' + module?._id]"
                        routerLinkActive="active" class="nav-link  cursor-pointer">
                        <span style="position: relative;">
                            <i class="pi pi-fw" [ngClass]="getModuleIcon(module?.name)"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span class="badge badge-danger count-badge" *ngIf="getItemCount(module?._id) !== 0" >{{getItemCount(module?._id)}}</span>
                        </span>

                        <p>{{ module?.name }}</p>
                    </a>
                </li>
            </ul>
            
        </nav>
        <!-- /.sidebar-menu -->
    </div>
    <!-- Logout Option -->
    <div class="sidebar-logout">
        <nav>
            <ul style="background-color: white;" class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li class="nav-item">
                    <a (click)="handleLogout()" class="nav-link  cursor-pointer">
                        &nbsp;&nbsp; <i class="fa-solid fa-power-off"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <p>
                            Sign Out
                        </p>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
    <!-- /.sidebar -->
</aside>
<app-loader *ngIf="isLoading"></app-loader>
<nav class="navbar navbar-expand main-header navbar-dark" style="background-color: var(--background-color)">
    <ul class="navbar-nav">
        <li class="nav-item">
            <a href="javascript:;" data-widget="pushmenu" class="nav-link"><i class="fas fa-bars"
                    style="color:var(--color)"></i></a>
        </li>
    </ul>
    <ul class="navbar-nav" style="text-align:center; color:var(--color);">
        <!-- Centered content can be added here if needed -->
    </ul>
    <ul class="navbar-nav ml-auto">
        <li class="nav-item">
            <a (click)="viewLinkRequest()" href="javascript:;" class="nav-link">
                <i class="fas fa-link" style="color:var(--color); font-size:18px;"></i>
                <!-- <span class="badge badge-danger" *ngIf="notificationCount > 0">{{ notificationCount }}</span> -->
            </a>
        </li>
        <li class="nav-item">
            <a (click)="viewNotification()" href="javascript:;" class="nav-link">
                <i class="fas fa-bell" style="color:var(--color); font-size:18px;"></i>
                <span class="badge badge-danger" *ngIf="notificationCount > 0">{{ notificationCount }}</span>
            </a>
        </li>
        <!-- <li *ngIf="!(loginUser?.role === 'admin') && !(loginUser?.role === 'super-admin') " class="nav-item">
            <a (click)="handleLogout()" href="javascript:;" class="nav-link">
                <i class="fas fa-power-off" style="color:var(--color); font-size:18px;"></i>
            </a>
        </li> -->
        <li *ngIf="(loginUser?.role === 'admin') || (loginUser?.role === 'super-admin') " class="nav-item dropdown">


            <a class="nav-link" data-toggle="dropdown" href="#">
                <img *ngIf="loginUser?.profileImage"
                    src="https://tejilo.s3.us-east-2.amazonaws.com/images/{{loginUser?.profileImage}}"
                    class="brand-image img-circle " style="opacity: .8; height: 25px; width: 25px;" />
                <img *ngIf="!loginUser.profileImage" src="assets/user-icon.png" class="brand-image img-circle "
                    style="opacity: .8; height: 25px; width: 25px;" />
            </a>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <div class="container profile-section mt-3 mb-3">
                    <div class="row">
                        <div class="col-sm-2 ">
                            <img *ngIf="loginUser?.profileImage"
                                src="https://tejilo.s3.us-east-2.amazonaws.com/images/{{loginUser?.profileImage}}"
                                height="40px" width="40px" class="brand-image img-circle " />
                            <img *ngIf="!loginUser.profileImage" src="assets/user-icon.png" height="40px" width="40px"
                                class="brand-image img-circle " />
                        </div>
                        <div class="col-sm-10 mr-auto ">
                            <div class="profile-details">
                                <span class="profile-name  fw-bolder mb-4"><b>{{loginUser?.fullName}}</b></span><br>
                                <span class="light-success mt-2">{{loginUser?.role}}</span><br>
                                <span class="profile-profession text-muted">{{loginUser?.email}}</span>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="dropdown-divider"></div>

                <a [routerLink]="['/home/ha-admin/change-password']" routerLinkActive="router-link-active"
                    class="dropdown-item">
                    <i class="fas fa-lock"></i> Change Password
                </a>

                <div class="dropdown-divider"></div>
                <a href="javascript:void(0)" (click)="handleLogout()" class="dropdown-item">
                    <i class="fas fa-sign-out-alt"></i> Logout
                </a>
            </div>

        </li>
    </ul>
</nav>

<!-- <p-overlayPanel #op>
    <div class="card scrollable-card">
        <div class="flex align-items-center justify-content-between mb-4">
            <h5>Notifications</h5>
        </div>
        
        <ul *ngIf="!notificationList.length" class="p-0 mx-0 mt-0 mb-4 list-none">
            <li class="text-center text-danger">
                <i class="pi pi-database"></i>
                No Records Found
            </li>
        </ul>

        <ul *ngFor="let notify of notificationList" class="p-0 mx-0 mt-0 mb-4 list-none">
            <li class="flex align-items-center py-2 border-bottom-1 surface-border">
                <div *ngIf="notify.title === 'accepted'"
                    class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                    <i class="pi pi-verified text-xl text-blue-500"></i>
                </div>
                <div *ngIf="notify.title === 'request'"
                    class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                    <i class="pi pi-user-plus text-xl text-blue-500"></i>
                </div>
                <div *ngIf="notify.title === 'rejected' || notify.title === 'end' "
                    class="w-3rem h-3rem flex align-items-center justify-content-center bg-pink-100 border-circle mr-3 flex-shrink-0">
                    <i class="pi pi-exclamation-triangle text-xl text-pink-500"></i>
                </div>
                <span class="text-900 line-height-3">
                    {{notify.message}}
                    <div class="text-gray-500 text-sm">{{ notify.createdAt | date:'short' }}</div>
                </span>
            </li>
        </ul>
    </div>
</p-overlayPanel> -->



<p-sidebar styleClass="w-25rem" [(visible)]="notificationSidebar" position="right">
    <h4>Notifications</h4>
    <br>
    <ul *ngIf="!notificationList?.length" class="p-0 mx-0 mt-0 mb-4 list-none">
        <li class="text-center text-danger">
            <i class="pi pi-database"></i>
            No Records Found
        </li>
    </ul>
    <ul *ngFor="let notify of notificationList" class="p-0 mx-0 mt-0 mb-4 list-none">
        <li class="flex align-items-center py-2 border-bottom-1 surface-border">
            <div *ngIf="notify?.title === 'accepted'"
                class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                <i class="pi pi-verified text-xl text-blue-500"></i>
            </div>
            <div *ngIf="notify?.title === 'request'"
                class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                <i class="pi pi-user-plus text-xl text-blue-500"></i>
            </div>
            <div *ngIf="notify?.title === 'rejected' || notify?.title === 'end' "
                class="w-3rem h-3rem flex align-items-center justify-content-center bg-pink-100 border-circle mr-3 flex-shrink-0">
                <i class="pi pi-exclamation-triangle text-xl text-pink-500"></i>
            </div>
            <span class="text-900 line-height-3">
                {{notify?.message}}
                <div class="text-gray-500 text-sm">{{ notify?.createdAt | date:'short' }}</div>
            </span>
        </li>
    </ul>
</p-sidebar>
<p-sidebar styleClass="w-30rem" [(visible)]="linkRequestSidebar" position="right">
    <h4>Link Requests</h4>
    <p-table class="custom-table" [value]="linkRequestList" [rows]="10" [tableStyle]="{ 'min-width': '30rem' }">

        <ng-template pTemplate="header">
            <tr>
                <th>S.No</th>
                <th>Email</th>
                <th>Assign</th>
                <th>Action</th>

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex">
            <tr>
                <td>{{ i + 1 }}</td>
                <td>{{ item.email }}</td>
                <td>
                    <p-dropdown [appendTo]="'body'" 
                                [options]="unAssignedUserList" 
                                [(ngModel)]="item.selectedEmployee" 
                                optionLabel="employeeCode" 
                                [filter]="true"
                                filterBy="employeeCode" 
                                [showClear]="true" 
                                placeholder="Select a Employee">
                        <ng-template pTemplate="selectedItem" let-selectedOption>
                            <div class="flex align-items-center gap-2">
                                
                                <div>{{ selectedOption.employeeCode }}</div>
                            </div>
                        </ng-template>
                        <ng-template let-emp pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                
                                <div>{{ emp.employeeCode }}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </td>
                <td>
                    <div class="flex gap-2">
                        
                        <button class="action-button" (click)="assign(item, item.selectedEmployee)">Accept</button>
                        <button (click)="rejectRequest(item)" class="action-button">Reject</button>
                    </div>
                </td>
            </tr>
        </ng-template>
        
        <ng-template pTemplate="footer">
            <tr *ngIf="!linkRequestList?.length">
                <td colspan="9" class="text-center text-danger">
                    <i class="pi pi-database"></i>
                    No Records Found
                </td>
            </tr>
          

        </ng-template>
    </p-table>

</p-sidebar>
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from './layout/app.layout.module';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ShareModule } from './share/share.module';
import { environment } from 'src/environments/environment';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NetworkInterceptor } from './services/network.interceptor';
import { ToastService } from './services/toast.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CanAccessGuard } from './guards/can-access.guard';
import { HaAuthGuard } from './guards/ha-admin.guard';
import { ExcelService } from './services/excel.service';
import { SocketService } from './services/socket.service';
import { UserRefreshService } from './services/refresh-service';
import { LayoutService } from './layout/service/app.layout.service';
import { DrAuthGuard } from './guards/drAuth.guard';
import { DpAuthGuard } from './guards/dpAuth.guard';
import { WaitingAuthGuard } from './guards/waitingGuard';
import { StaffAuthGuard } from './guards/staffAuth.guard';
const config: SocketIoConfig = {
  url: environment.socketUrl, options: {
    path: '/cdsocket',
    auth:{token:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImlkIjoiNjZjZGFhOTg4Mjg1MGEyYzdiZTliMjI2IiwiZW1haWwiOiJzdXJ5YWFyeWFsMjA4MUBnbWFpbC5jb20ifSwiaWF0IjoxNzI0NzU0ODcxLCJleHAiOjE3MjUzNTk2NzF9.x3p9DDG3sfo15480aD7TFUQZkaO1Ve1m-g3_5nx6_es'}
  }
}




@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppLayoutModule,
    ShareModule,
    SocketIoModule.forRoot(config),


  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: NetworkInterceptor, multi: true },
    ToastService, MessageService, ConfirmationService, HaAuthGuard, CanAccessGuard, ExcelService, SocketService,LayoutService,UserRefreshService,WaitingAuthGuard
,    DrAuthGuard, DpAuthGuard,StaffAuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { SocketService } from './services/socket.service';
import { AppStorageService } from './services/app-storage.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LayoutService } from './services/layout-service';
import { UserRefreshService } from './services/refresh-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private socketService: SocketService, private appstorageservice: AppStorageService, private router: Router, private layoutService: LayoutService, private userRefreshService:UserRefreshService) { }
  public loginUser: any = '';

  async ngOnInit() {
    await this.fetchLoginUser();
    this.setupSocketListeners();
    this.userRefreshService.refresh$.subscribe(() => {
      this.fetchLoginUser();
    });
  }
  title = '01.corporate-discount';
  async fetchLoginUser() {
    const user =  await this.appstorageservice.getdata(environment.localSTORAGE.profile);
    // if key and value on the local storage then parse to json
    if (user) {
      this.loginUser = JSON.parse(user);

    }

    // if (this.loginUser.type === '1') {
    //   this.changeTheme('lara-dark-indigo', 'dark');

    // } else if (this.loginUser.type === '2') {
    //   this.changeTheme('tailwind-light', 'light');

    // }
  }

  setupSocketListeners() {
    // this is handle logout when admin sets account inactive
    this.socketService.inactive().subscribe((userId: any) => {
      // Check if this.loginUser is defined before accessing its properties
      if (this.loginUser && this.loginUser._id == userId) {
        this.handleLogout();
      }

    });

    this.socketService.delete().subscribe((userId: any) => {
      // Check if this.loginUser is defined before accessing its properties
      if (this.loginUser && this.loginUser._id == userId) {
        this.handleLogout();
      }


    });
  }

  handleLogout() {
    this.appstorageservice.removedata(environment.localSTORAGE.token);
    this.appstorageservice.removedata(environment.localSTORAGE.profile);
    // location.reload(); 
    this.router.navigate(['/auth/login']);
  }


  changeTheme(theme: string, colorScheme: string) {
    const themeLink = <HTMLLinkElement>document.getElementById('theme-css');
    const newHref = themeLink.getAttribute('href')!.replace(this.layoutService.config.theme, theme);
    this.layoutService.config.colorScheme
    this.replaceThemeLink(newHref, () => {
      this.layoutService.config.theme = theme;
      this.layoutService.config.colorScheme = colorScheme;
      this.layoutService.onConfigUpdate();
    });
  }
  replaceThemeLink(href: string, onComplete: Function) {
    const id = 'theme-css';
    const themeLink = <HTMLLinkElement>document.getElementById('theme-css');
    const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true);

    cloneLinkElement.setAttribute('href', href);
    cloneLinkElement.setAttribute('id', id + '-clone');

    themeLink.parentNode!.insertBefore(cloneLinkElement, themeLink.nextSibling);

    cloneLinkElement.addEventListener('load', () => {
      themeLink.remove();
      cloneLinkElement.setAttribute('id', id);
      onComplete();
    });
  }
}

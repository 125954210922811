import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppStorageService } from '../services/app-storage.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class DpAuthGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private appStorageService: AppStorageService,
        private router: Router
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const currentUser = JSON.parse(this.appStorageService.getdata(environment.localSTORAGE.profile));
        if (currentUser.type === '1') {
            return true;
        } else {
            return false;
        }
    }
}
